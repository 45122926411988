import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import SystemService from '@/services/SystemService.js'
import AuthService from '@/services/AuthService.js'
import { useConfigStore } from '@/stores/config.js'
import { useEmployeeStore } from '@/stores/employee.js'
import { useServiceStore } from '@/stores/service.js'
import ClientsService from '@/services/ClientsService.js'
import EmployeesService from '@/services/EmployeesService.js'
import { isBefore } from 'date-fns'

export const useUserStore = defineStore('user', () => {
  const configStore = useConfigStore()
  const employeeStore = useEmployeeStore()
  const serviceStore = useServiceStore()

  const loginEmail = ref('')
  const signupPassword = ref('')
  const token = ref(localStorage.getItem('token') || '')
  const role = ref(localStorage.getItem('role') || 'ROLE_USER')
  const name = ref(localStorage.getItem('name') || '')
  const email = ref(localStorage.getItem('email') || '')
  const photo = ref(localStorage.getItem('photo') || null)
  const userId = ref(localStorage.getItem('userId') || null)
  const smsAvailable = ref(localStorage.getItem('smsAvailable') || null)
  const expirationDate = ref(localStorage.getItem('expirationDate') || null)
  const appointmentsPerMonth = ref(parseInt(localStorage.getItem('appointmentsPerMonth')) || null)
  const appointmentsThisMonth = ref(parseInt(localStorage.getItem('appointmentsThisMonth')) || null)
  const showStats = ref(localStorage.getItem('showStats') || null)
  const maxEmployees = ref(localStorage.getItem('maxEmployees') || null)
  const freeSms = ref(localStorage.getItem('freeSms') || null)
  const packageName = ref(localStorage.getItem('package') || null)

  const isAuthenticated = computed(() => !!token.value || !!localStorage.getItem('token'))
  const isAdmin = computed(() => role.value === 'ROLE_OWNER')
  const isSupervisor = computed(() => role.value === 'ROLE_SUPERVISOR')
  const isAtLeastSupervisor = computed(() => role.value === 'ROLE_SUPERVISOR' || role.value === 'ROLE_OWNER')

  const isSubscriptionExpired = computed(() => isBefore(expirationDate.value, new Date()))
  const isStarter = computed(() => packageName.value === 'starter')
  const isPremium = computed(() => packageName.value === 'premium')
  const isTeam = computed(() => packageName.value === 'team')
  const hasAppointmentsLeftThisMonth = computed(() => !isStarter.value || (isStarter.value && (appointmentsThisMonth.value < appointmentsPerMonth.value)))

  const checkEmail = async (email = null) => {
    const emailToCheck = email || loginEmail.value
    if (emailToCheck.trim().length === 0) {
      return new Promise((reject) => reject())
    }
    const { data, error } = await SystemService.checkUser(emailToCheck.trim())
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve(data.value.exists)
      }
    })
  }

  const getPermission = (permission) => {
    switch (permission) {
      case 'showStats':
        return showStats.value || localStorage.getItem('showStats')
      case 'maxEmployees':
        return maxEmployees.value || localStorage.getItem('maxEmployees')
      case 'freeSms':
        return freeSms.value || localStorage.getItem('freeSms')
      case 'appointmentsPerMonth':
        return appointmentsPerMonth.value || localStorage.getItem('appointmentsPerMonth')
    }
  }

  const loginUser = async (password) => {
    const { data, error } = await AuthService.login({ username: loginEmail.value, password: password })
    return new Promise((resolve, reject) => {
      if (error.value) {
        localStorage.removeItem('token')
        localStorage.removeItem('role')
        localStorage.removeItem('name')
        localStorage.removeItem('email')
        localStorage.removeItem('photo')
        localStorage.removeItem('userId')
        localStorage.removeItem('smsAvailable')
        localStorage.removeItem('expirationDate')
        localStorage.removeItem('appointmentsPerMonth')
        localStorage.removeItem('appointmentsThisMonth')
        localStorage.removeItem('showStats')
        localStorage.removeItem('maxEmployees')
        localStorage.removeItem('freeSms')
        localStorage.removeItem('package')
        reject(error.value)
      } else {
        const user = data.value
        localStorage.setItem('token', user.token)
        localStorage.setItem('role', user.role)
        localStorage.setItem('name', user.name)
        localStorage.setItem('email', user.email)
        localStorage.setItem('photo', user.photo)
        localStorage.setItem('userId', user.id)
        localStorage.setItem('smsAvailable', user.smsAvailable)
        localStorage.setItem('expirationDate', user.expirationDate)
        localStorage.setItem('appointmentsPerMonth', user.appointmentsPerMonth)
        localStorage.setItem('appointmentsThisMonth', user.appointmentsThisMonth)
        localStorage.setItem('showStats', user.showStats)
        localStorage.setItem('maxEmployees', user.maxEmployees)
        localStorage.setItem('freeSms', user.freeSms)
        localStorage.setItem('package', user.package)
        token.value = user.token
        role.value = user.role
        name.value = user.name
        email.value = user.email
        photo.value = user.photo
        userId.value = user.id
        smsAvailable.value = user.smsAvailable
        expirationDate.value = user.expirationDate
        appointmentsPerMonth.value = user.appointmentsPerMonth
        appointmentsThisMonth.value = user.appointmentsThisMonth
        showStats.value = user.showStats || true
        maxEmployees.value = user.maxEmployees
        freeSms.value = user.freeSms
        packageName.value = user.package
        resolve(user)
      }
    })
  }

  const loadInitialData = () => {
    return Promise.all([
      new Promise((resolve, reject) => {
        configStore.fetchConfig().then((configuration) => {
          resolve(configuration)
        }).catch(err => {
          reject(err)
        })
      }),
      employeeStore.fetchEmployees(),
      serviceStore.fetchServices()
    ])
  }

  const logoutUser = () => {
    return new Promise((resolve) => {
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      localStorage.removeItem('name')
      localStorage.removeItem('email')
      localStorage.removeItem('photo')
      localStorage.removeItem('userId')
      localStorage.removeItem('smsAvailable')
      localStorage.removeItem('language')
      localStorage.removeItem('configuration')
      localStorage.removeItem('company')
      localStorage.removeItem('openingHours')
      localStorage.removeItem('calendarView')
      localStorage.removeItem('expirationDate')
      localStorage.removeItem('appointmentsPerMonth')
      localStorage.removeItem('appointmentsThisMonth')
      localStorage.removeItem('showStats')
      localStorage.removeItem('maxEmployees')
      localStorage.removeItem('freeSms')
      localStorage.removeItem('package')
      localStorage.removeItem('tutorial')
      loginEmail.value = ''
      token.value = ''
      role.value = 'ROLE_USER'
      name.value = ''
      photo.value = ''
      userId.value = null
      smsAvailable.value = null
      expirationDate.value = null
      appointmentsPerMonth.value = null
      showStats.value = false
      maxEmployees.value = null
      freeSms.value = null
      packageName.value = null

      resolve()
    })
  }

  const fetchClientInfo = async () => {
    const { data, error } = await ClientsService.get()
    const client = data.value
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        smsAvailable.value = client.smsAvailable
        expirationDate.value = client.expirationDate
        appointmentsPerMonth.value = client.appointmentsPerMonth
        showStats.value = client.showStats || true
        maxEmployees.value = client.maxEmployees
        freeSms.value = client.freeSms
        packageName.value = client.package
        localStorage.setItem('smsAvailable', client.smsAvailable)
        localStorage.setItem('expirationDate', client.expirationDate)
        localStorage.setItem('appointmentsPerMonth', client.appointmentsPerMonth)
        localStorage.setItem('appointmentsThisMonth', client.appointmentsThisMonth)
        localStorage.setItem('showStats', client.showStats)
        localStorage.setItem('maxEmployees', client.maxEmployees)
        localStorage.setItem('freeSms', client.freeSms)
        localStorage.setItem('package', client.package)
        resolve(client)
      }
    })
  }

  const signup = async (data) => {
    const { error } = await ClientsService.register(data)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const requestPasswordReset = async (email) => {
    await AuthService.requestPasswordReset(email)
    return new Promise((resolve) => {
      resolve()
    })
  }

  const verifyPasswordResetToken = async (token) => {
    const { error } = await AuthService.verifyPasswordResetToken(token)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject()
      } else {
        resolve()
      }
    })
  }

  const resetPassword = async (token, password) => {
    const { error } = await AuthService.resetPassword(token, password)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject()
      } else {
        resolve()
      }
    })
  }

  const refreshProfile = async () => {
    const { data, error } = await EmployeesService.getById(userId.value)
    if (error.value) {
      return new Promise((reject) => reject())
    }
    name.value = data.value.name
    photo.value = data.value.photo
    email.value = data.value.username
    localStorage.setItem('name', data.value.name)
    localStorage.setItem('email', data.value.email)
    localStorage.setItem('photo', data.value.photo)

    return new Promise((resolve) => resolve())
  }

  const deleteAccount = async () => {
    const { error } = await ClientsService.delete()
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject()
      } else {
        logoutUser()
        resolve()
      }
    })
  }

  return {
    checkEmail,
    loginEmail,
    loginUser,
    loadInitialData,
    isAuthenticated,
    isAdmin,
    isSupervisor,
    isAtLeastSupervisor,
    logoutUser,
    packageName,
    name,
    smsAvailable,
    expirationDate,
    maxEmployees,
    freeSms,
    userId,
    fetchClientInfo,
    appointmentsPerMonth,
    getPermission,
    signupPassword,
    signup,
    requestPasswordReset,
    verifyPasswordResetToken,
    resetPassword,
    photo,
    email,
    refreshProfile,
    isSubscriptionExpired,
    isStarter,
    isPremium,
    isTeam,
    deleteAccount,
    appointmentsThisMonth,
    hasAppointmentsLeftThisMonth
  }
})
