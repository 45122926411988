import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import ConfigService from '@/services/ConfigService.js'
import { format, setDay } from 'date-fns'
import { enUS } from 'date-fns/locale'

export const useConfigStore = defineStore('config', () => {
  const configuration = ref(null)
  const company = ref(null)
  const openingHours = ref(null)

  const getConfigItem = (item) => {
    return configuration[item] || JSON.parse(localStorage.getItem('configuration'))[item] || null
  }

  const storeOpeningHours = computed(() => {
    return openingHours.value || JSON.parse(localStorage.getItem('openingHours'))
  })

  const companyInfo = computed(() => {
    return company.value || JSON.parse(localStorage.getItem('company'))
  })

  const specialHours = computed(() => {
    const specialHours = {
      1: { from: 0, to: 24 * 60, class: 'closed' },
      2: { from: 0, to: 24 * 60, class: 'closed' },
      3: { from: 0, to: 24 * 60, class: 'closed' },
      4: { from: 0, to: 24 * 60, class: 'closed' },
      5: { from: 0, to: 24 * 60, class: 'closed' },
      6: { from: 0, to: 24 * 60, class: 'closed' },
      7: { from: 0, to: 24 * 60, class: 'closed' }
    }

    if (!openingHours.value) {
      return specialHours
    }

    for (let i = 1; i <= 7; i++) {
      const day = format(setDay(new Date(), i), 'EEEE', { locale: enUS }).toLowerCase()
      if (openingHours.value[day]) {
        specialHours[i] = [
          {
            from: 0,
            to: (openingHours.value[day].start1.split(':')[0] * 60) + parseInt(openingHours.value[day].start1.split(':')[1]),
            class: 'closed'
          }
        ]
        if (openingHours.value[day].start2 && openingHours.value[day].end2) {
          specialHours[i].push(
            {
              from: (openingHours.value[day].end1.split(':')[0] * 60) + parseInt(openingHours.value[day].end1.split(':')[1]),
              to: (openingHours.value[day].start2.split(':')[0] * 60) + parseInt(openingHours.value[day].start2.split(':')[1]),
              class: 'closed'
            }
          )
          specialHours[i].push(
            {
              from: (openingHours.value[day].end2.split(':')[0] * 60) + parseInt(openingHours.value[day].end2.split(':')[1]),
              to: 24 * 60,
              class: 'closed'
            }
          )
        } else {
          specialHours[i].push(
            {
              from: (openingHours.value[day].end1.split(':')[0] * 60) + parseInt(openingHours.value[day].end1.split(':')[1]),
              to: 24 * 60,
              class: 'closed'
            }
          )
        }
      }
    }

    return specialHours
  })

  const fetchConfig = async () => {
    const { data, error } = await ConfigService.get()
    const conf = data.value.configuration
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        configuration.value = conf
        company.value = conf.company
        openingHours.value = conf.openingHours
        localStorage.setItem('configuration', JSON.stringify(conf))
        localStorage.setItem('language', conf.language)
        localStorage.setItem('company', JSON.stringify(conf.company))
        localStorage.setItem('openingHours', JSON.stringify(conf.openingHours))
        resolve(conf)
      }
    })
  }

  const saveLanguage = async (language) => {
    const { error } = await ConfigService.saveLanguage(language)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        if (configuration.value) {
          configuration.value.language = language
        }
        const currentConfig = JSON.parse(localStorage.getItem('configuration'))
        currentConfig.language = language
        localStorage.setItem('configuration', JSON.stringify(currentConfig))
        localStorage.setItem('language', language)
        resolve()
      }
    })
  }

  const saveCompany = async (company) => {
    const { error } = await ConfigService.saveCompany(company)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const saveOpeningHours = async (openingHours) => {
    const { data, error } = await ConfigService.saveOpeningHours(openingHours)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        openingHours.value = data.value.openingHours
        localStorage.setItem('openingHours', JSON.stringify(data.value.openingHours))
        resolve()
      }
    })
  }

  const updateNotificationSettings = async (smsStrategy = 'never', smsName = 'Appointr', notificationHours = 2) => {
    const { error } = await ConfigService.updateNotificationSettings({
      smsStrategy: smsStrategy,
      smsName: smsName,
      notificationHours: notificationHours
    })
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        fetchConfig()
        resolve()
      }
    })
  }

  return {
    getConfigItem,
    specialHours,
    fetchConfig,
    storeOpeningHours,
    saveLanguage,
    companyInfo,
    saveCompany,
    saveOpeningHours,
    updateNotificationSettings
  }
})
