import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import EmployeesService from '@/services/EmployeesService.js'
import { formatISO9075 } from 'date-fns'
import { useUserStore } from '@/stores/user.js'

export const useEmployeeStore = defineStore('employee', () => {
  const employees = ref([])
  const currentEmployee = ref(null)
  const schedule = ref([])
  const selectedSchedule = ref({
    id: null,
    employee: { id: null, name: '' },
    shifts: { start1: null, end1: null, start2: null, end2: null },
    workingDate: new Date(),
    timeOff: false,
    timeOffReason: 'time-off'
  })

  const employee = computed(() => currentEmployee.value)
  const activeEmployees = computed(() => employees.value.filter(emp => emp.active === true))
  const bookableEmployees = computed(() => employees.value.filter(emp => (emp.active === true && emp.bookable === true)))
  const bookableEmployeesShort = computed(() => {
    return bookableEmployees.value.map(be => {
      return {
        id: be.id,
        name: be.name
      }
    })
  })

  const fetchEmployees = async () => {
    const { data, error } = await EmployeesService.get()
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        employees.value = data.value
        resolve(data.value)
      }
    })
  }

  const fetchSchedule = async (date) => {
    const { data, error } = await EmployeesService.getSchedule(formatISO9075(date, { representation: 'date' }))
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        schedule.value = data.value
        resolve(data.value)
      }
    })
  }

  const fetchEmployee = async (id) => {
    const { data, error } = await EmployeesService.getById(id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        currentEmployee.value = data.value
        currentEmployee.value.hasAccess = data.value.username?.length > 0
        resolve(data.value)
      }
    })
  }

  const getEmployeePhoto = (employee) => {
    if (employee && employee.photo) {
      return employee.photo
    }

    return new URL('../assets/employee_default_avatar.png', import.meta.url).href
  }

  const createEmployee = async (employee, photo = null) => {
    const { data, error } = await EmployeesService.create(employee)
    const employeeData = data.value
    if (error.value) {
      return new Promise((reject) => reject(error.value))
    }
    currentEmployee.value = employeeData
    currentEmployee.value.hasAccess = data.value.username?.length > 0
    if (photo) {
      let formData = new FormData()
      formData.append('photo', photo)
      const { error } = await EmployeesService.uploadPhoto(employeeData.id, formData)
      return new Promise((resolve, reject) => {
        if (error.value) {
          reject(error.value)
        } else {
          resolve(employeeData.value)
        }
      })
    }
    return new Promise((resolve) => {
      resolve(employeeData.value)
    })
  }

  const updateEmployee = async (employee, photo = null) => {
    const { error } = await EmployeesService.update(employee)
    if (error.value) {
      return new Promise((reject) => reject(error.value))
    }
    if (photo) {
      let formData = new FormData()
      formData.append('photo', photo)
      const { error } = await EmployeesService.uploadPhoto(employee.id, formData)
      return new Promise((resolve, reject) => {
        if (error.value) {
          reject(error.value)
        } else {
          fetchEmployee(employee.id)
          resolve()
        }
      })
    }
    return new Promise((resolve) => {
      fetchEmployee(employee.id)
      resolve()
    })
  }

  const updatePhoto = async (employeeId, photo) => {
    let formData = new FormData()
    formData.append('photo', photo)
    const { error } = await EmployeesService.uploadPhoto(employeeId, formData)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const saveSchedule = async () => {
    const { error } = await EmployeesService.saveSchedule(selectedSchedule.value)

    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const deleteSchedule = async () => {
    const { error } = await EmployeesService.deleteSchedule(selectedSchedule.value)

    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const changePassword = async (currentPassword, newPassword) => {
    const { error } = await EmployeesService.changePassword(currentPassword, newPassword, useUserStore().userId)

    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const deleteEmployee = async (id) => {
    const { error } = await EmployeesService.delete(id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  return {
    employees,
    currentEmployee,
    employee,
    schedule,
    selectedSchedule,
    activeEmployees,
    bookableEmployees,
    bookableEmployeesShort,
    fetchEmployees,
    getEmployeePhoto,
    fetchSchedule,
    createEmployee,
    fetchEmployee,
    updateEmployee,
    saveSchedule,
    deleteSchedule,
    updatePhoto,
    changePassword,
    deleteEmployee
  }
})
