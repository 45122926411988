import { ref } from 'vue'
import { defineStore } from 'pinia'
import ServicesService from '@/services/ServicesService.js'

export const useServiceStore = defineStore('service', () => {
  const services = ref([])
  const servicesTotal = ref(0)
  const service = ref()

  const fetchServices = async () => {
    const { data, error } = await ServicesService.get()
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        services.value = data.value
        servicesTotal.value = data.value.length
        resolve(data.value)
      }
    })
  }

  const searchServices = async (search, page = 1, amount = 15, sort = 'name', direction = 'asc') => {
    const { data, error } = await ServicesService.search(search, page, amount, sort, direction)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        services.value = data.value.data
        servicesTotal.value = data.value.count
        resolve(data.value)
      }
    })
  }

  const saveService = async () => {
    let svc, err
    if (service.value.id) {
      const { error } = await ServicesService.update(service.value)
      err = error.value
    } else {
      const { data, error } = await ServicesService.create(service.value)
      svc = data.value
      err = error.value
    }
    return new Promise((resolve, reject) => {
      if (err) {
        reject(err)
      } else {
        resolve(svc)
      }
    })
  }

  const initService = () => {
    service.value = {
      id: null,
      name: '',
      description: null,
      duration: 30,
      price: 0,
      color: '#7DC6CC',
      displayInBookingPage: false,
      employees: []
    }
  }

  const deleteService = async () => {
    const { error } = await ServicesService.delete(service.value.id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  return { services, servicesTotal, fetchServices, searchServices, saveService, service, initService, deleteService }
})
