import { useFetch } from '@/client.js'

class ServicesService {
  get = () => useFetch('services').get().json()
  search = (search, page, amount, sort, direction) => useFetch(`services/search?search=${search}&page=${page}&amount=${amount}&sort=${sort}&direction=${direction}`).get().json()
  create = (service) => useFetch(`services`).post(service).json()
  update = (service) => useFetch(`services/${service.id}`).put(service)
  delete = (id) => useFetch(`services/${id}`).delete()
}

export default new ServicesService()