import { ref } from 'vue'
import { defineStore } from 'pinia'
import CustomersService from '@/services/CustomersService.js'

export const useCustomerStore = defineStore('customer', () => {
  const customers = ref([])
  const currentCustomer = ref()
  const customersTotal = ref(0)

  const filterAppointmentsByFake = (fake = false) => {
    if (fake === false) {
      return currentCustomer.value.appointments.filter(appointment => appointment.isFake === false)
    }

    return currentCustomer.value.appointments
  }

  const fetchAllCustomers = async () => {
    const { data, error } = await CustomersService.search('', 1, 15, 'name', 'asc')
    const custs = data.value.data
    const count = data.value.count
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        customers.value = custs
        customersTotal.value = count
        resolve(custs)
      }
    })
  }

  const searchCustomers = async (search, page = 1, amount = 15, sort = 'name', direction = 'asc') => {
    const { data, error } = await CustomersService.search(search, page, amount, sort, direction)
    const custs = data.value.data
    const count = data.value.count
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        customers.value = custs
        customersTotal.value = count
        resolve(custs)
      }
    })
  }

  const createCustomer = async (customer) => {
    const { data, error } = await CustomersService.create(customer)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve(data.value)
      }
    })
  }

  const loadCustomer = (id) => {
    currentCustomer.value = { id: id }
    return refreshCustomer()
  }

  const refreshCustomer = async () => {
    const { data, error } = await CustomersService.getById(currentCustomer.value.id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        currentCustomer.value = data.value
        resolve(data)
      }
    })
  }

  const toggleCommunication = async () => {
    const { error } = await CustomersService.toggleCommunication(currentCustomer.value.id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        currentCustomer.value.allowCommunication = !currentCustomer.value.allowCommunication
        resolve()
      }
    })
  }

  const toggleBlacklist = async () => {
    const { error } = await CustomersService.toggleBlacklist(currentCustomer.value.id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        currentCustomer.value.isBlacklisted = !currentCustomer.value.isBlacklisted
        resolve()
      }
    })
  }

  const updateCustomer = async () => {
    const { error } = await CustomersService.update(currentCustomer.value)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  const deleteCustomer = async () => {
    const { error } = await CustomersService.delete(currentCustomer.value.id)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject(error.value)
      } else {
        resolve()
      }
    })
  }

  return {
    customers,
    currentCustomer,
    customersTotal,
    fetchAllCustomers,
    searchCustomers,
    createCustomer,
    refreshCustomer,
    toggleCommunication,
    toggleBlacklist,
    updateCustomer,
    loadCustomer,
    deleteCustomer,
    filterAppointmentsByFake
  }
})
