import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import SystemService from '@/services/SystemService.js'
import PackagesService from '@/services/PackagesService.js'
import { useUserStore } from '@/stores/user.js'

export const useAppStore = defineStore('app', () => {
  const defaultSnackbar = {
    show: false,
    color: 'success',
    headerText: null,
    content: ''
  }
  const defaultConfirmationDialog = {
    show: false,
    color: 'success',
    title: '',
    message: '',
    callback: () => {}
  }

  const loading = ref(false)
  const snackbar = ref(defaultSnackbar)
  const confirmationDialog = ref(defaultConfirmationDialog)
  const fabExpanded = ref(false)
  const serverPingInterval = ref()
  const reloadClientInfoInterval = ref()
  const serverStatus = ref(true)
  const packages = ref([])
  const smsUpgradeModal = ref(false)
  const reportsUpgradeModal = ref(false)
  const recurringUpgradeModal = ref(false)
  const teamUpgradeModal = ref(false)
  const appointmentsUpgradeModal = ref(false)

  const isSmallScreen = computed(() => window.matchMedia('(max-width: 1023px)').matches)

  const load = () => {
    loading.value = true
  }
  const unload = () => {
    loading.value = false
  }

  const showSnackbar = (color, headerText, content, timeout = 3000) => {
    snackbar.value.headerText = headerText
    snackbar.value.content = content
    snackbar.value.color = color
    snackbar.value.show = true
    if (timeout > 0) {
      setTimeout(() => {
        snackbar.value.show = false
      }, timeout)
    }
  }

  const dismissSnackbar = () => {
    snackbar.value.show = false
  }

  const showConfirmationDialog = (color, title, message, callback) => {
    confirmationDialog.value.show = true
    confirmationDialog.value.color = color
    confirmationDialog.value.title = title
    confirmationDialog.value.message = message
    confirmationDialog.value.callback = callback
  }

  const dismissConfirmationDialog = () => {
    confirmationDialog.value.show = false
  }

  const toggleFab = () => {
    fabExpanded.value = !fabExpanded.value
  }

  const startPingingServer = () => {
    serverPingInterval.value = setInterval(async () => {
      const { error } = await SystemService.ping()
      serverStatus.value = !error.value
    }, 60000)
  }

  const stopPingingServer = () => {
    if (serverPingInterval.value) {
      clearInterval(serverPingInterval.value)
    }
  }

  const startReloadingClientInfo = () => {
    reloadClientInfoInterval.value = setInterval(async () => {
      await useUserStore().fetchClientInfo()
    }, 60000)
  }

  const stopReloadingClientInfo = () => {
    if (reloadClientInfoInterval.value) {
      clearInterval(reloadClientInfoInterval.value)
    }
  }

  const sendFeedback = async (message) => {
    const { error } = await SystemService.createFeedback(message)
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject()
      } else {
        resolve()
      }
    })
  }

  const fetchPackages = async () => {
    const { data, error } = await PackagesService.getPackages()
    return new Promise((resolve, reject) => {
      if (error.value) {
        reject()
      } else {
        packages.value = data.value
        resolve()
      }
    })
  }

  return {
    loading,
    load,
    unload,
    showSnackbar,
    snackbar,
    dismissSnackbar,
    toggleFab,
    isSmallScreen,
    startPingingServer,
    stopPingingServer,
    serverStatus,
    sendFeedback,
    showConfirmationDialog,
    dismissConfirmationDialog,
    confirmationDialog,
    packages,
    fetchPackages,
    smsUpgradeModal,
    reportsUpgradeModal,
    recurringUpgradeModal,
    teamUpgradeModal,
    appointmentsUpgradeModal,
    startReloadingClientInfo,
    stopReloadingClientInfo
  }
})
